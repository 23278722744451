<template>
    Payment Policy
</template>

<script>
    export default {
        name: "PaymentPolicy"
    }
</script>

<style scoped>

</style>