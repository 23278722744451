<template>
    <toolbar/>
    <router-view/>
    <footer-default/>

</template>

<style lang="scss">
</style>
<script>
    import Toolbar from "./components/toolbar";
    import FooterDefault from "./components/footer-default";

    export default {
        components: {FooterDefault, Toolbar},

        watch: {
            $route(to) {
                if (Object.keys(to.meta).length > 0) {
                    document.title = to.meta.title;
                    if ("metaTags" in to.meta) {
                        to.meta.metaTags.map(function (metaTag) {
                            const tag = document.createElement("meta");
                            Object.keys(metaTag).forEach(key => {
                                tag.setAttribute(key, metaTag[key]);
                            });
                            document.head.appendChild(tag);
                        });
                    }
                }
            }
        }
    }

var Tawk_API=Tawk_API||{};
(function(){
var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
s1.async=true;
s1.src='https://embed.tawk.to/64cb5d1e94cf5d49dc6824fb/1h6t67ggr';
s1.charset='UTF-8';
s1.setAttribute('crossorigin','*');
s0.parentNode.insertBefore(s1,s0);
})();
</script>