<template>
     <section class="content">
        <div class="container">
<h1>Rental Check List Tip Sheet</h1>
<h5>Before You Rent a Car</h5>
<ul> <li>Talk to your insurance agent to verify what coverage you have when renting a car.</li>
<li>Talk to your road service provider to see if they cover service calls on rental cars.</li>
<li>Tuck a small flashlight into your carry-on bag.</li></ul>
<h5>At the Rental Car Host</h5>
<p>Ask the Host representative how they know the car you're renting has completed its
prerental maintenance check.</p>
<ul><li>Ask the Host representative what to do if the car breaks down.</li>
<li>Ask the Host representative what will happen if you lose the car key.</li>
<li>Ask for any phone numbers to call should the car get stuck or break down.</li></ul>
<h5>At the Car</h5>
<ul><li>Take a walk around; ensure there's no obvious damage.</li>
<li>Make sure the license plates are current.</li>
<li>Look at the tires for inflation, good tread, and even tread wear.</li>
<li>Get in and check the cleanliness and smell of the car.</li>
<li>Check the odometer.</li>
<li>Check the lights.</li>
<li>Spray the windshield to ensure that the washer system works and the wipers are in
good shape.</li>
<li>Ensure that there's an owners manual in the glove box.</li>
<li>Do under-hood and under-trunk inspections.</li>
<li>Check the trunk for a spare tire, jack and lug wrench.</li>
<li>Open the hood and check the fluids.</li>
<li>Get in and familiarize yourself with the cockpit.</li>
<li>Adjust the seats and mirrors.</li>
<li>NeighboursRental</li>
<li>Drive the car inside the rental lot before taking to the open road.</li></ul>
<h5>When You Drop Off the Car</h5>
<ul><li>Check mileage.</li>
<li>Check gas gauge.</li>
<li>Locate your cell phone, eyeglasses, wallet, and briefcase.</li>
<li>Check on and under front and back seats.</li>
<li>Check the trunk.</li>
<li>Check glove and storage compartments.</li>
<li>Inspect the car for damage.</li></ul>
 </div>
    </section>
</template>

<script>
export default {
        name: "rentalchecklist"
    }
</script>

<style scoped lang="scss">
.content {
        margin: 6rem 0;
    }
</style>
