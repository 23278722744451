<template>
    Safety Policy
</template>

<script>
    export default {
        name: "SafetyPolicy"
    }
</script>

<style scoped>

</style>