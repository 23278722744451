<template>
    <section>
        Stripe Host account
    </section>
</template>

<script>
    export default {
        name: "StripeHostAccount",

        methods: {
            async completeAccountLink()
            {
                window.ReactNativeWebView.postMessage("return");
            }
        },
        computed: {},
        created() {
            this.completeAccountLink();
        },
    }
</script>

<style scoped lang="scss">

</style>