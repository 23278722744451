<template>
    <section>
        Handle Transactipon
        {{gateway}}
        {{id}}
        {{type}}
    </section>
</template>

<script>
    export default {
        name: "HandleTransaction",
        props: {
            gateway: String,
            id: String,
            type: String
        },
        data() {
            return {
                isPaymentVerified: {
                    success: 1,
                    message: {
                        type: 'Rental',
                        id: this.id
                    }
                }
            }
        },
        methods: {
            async verifyTransaction() {
                //let response = await this.$store.dispatch("/transaction/verify/"+this.id);
                //this.transaction = response.data;
                window.ReactNativeWebView.postMessage(JSON.stringify(this.isPaymentVerified))
                // window.postMessage("Click in Vue");
                // window.postMessage(JSON.stringify(this.isPaymentVerified));
            }
        },
        computed: {},
        created() {
            this.verifyTransaction();
        },

    }
</script>

<style scoped lang="scss">

</style>