<template>
    <section id="header">
        <div class="content">
            <img class = "logo-image" src="@/assets/logo_transparent.png" />
            <h1><strong>nRIDE</strong> for your everyday travel needs</h1>
            <p>Car Rentals, Share Rides and earning money while hosting your Car</p>

            <div class="mt-3 app-download-buttons">
                <a href="https://apps.apple.com/app/nride-carpool-rental/id1607959386" target="_blank">
                <img src="@/assets/images/app/appstore.png" />
                </a>

                <a href="https://play.google.com/store/apps/details?id=com.neighbours.nride" target="_blank">
                <img src="@/assets/images/app/google_play.png" />
                </a>
            </div>

            <span class="more" @click="handleClick">Learn more about this application</span>
        </div>
    </section>

    <section id="services" class="container">
        <div class="row service" ref="targetRef">
            <div class="col-md-6">
                <img src="@/assets/images/home/rental.jpg">
            </div>
            <div class="col-md-6 content">

                <h1>Rental Bookings</h1>
                <p class="subtitle">Search from a variety of vehicle options and choose the perfect car for your
                    personal travel.</p>

                <a href="/services/rentals" class="more">Learn More about Rentals</a>

            </div>
        </div>


        <div class="row service">
            <div class="col-md-6 content">

                <h1>Share Rides</h1>
                <p class="subtitle">Hop in alongwith commuters going the same way. Share the ride, save the cost, enjoy
                    the company</p>
                <a href="services/shares" class="more">Learn More about Shares</a>
            </div>

            <div class="col-md-6">
                <img src="@/assets/images/home/share.jpg">
            </div>
        </div>

    </section>

    <section id="owner" class="container">
        <h1>Earn from your Car</h1>
        <p>Join in on our Owner program to earn from your vehicle by putting it up for rentals or share ride with other
            commuters on your trips</p>
        <a href="/services/host" class="more">Learn More about hosting your Car</a>

        <div class="row">

            <div class="col-md-4 content">
                <img src="@/assets/images/home/register.jpg">
                <h2>Register & Verify</h2>
                <p class="subtitle">Authenticate yourself in just a few steps. We like our customers to trust you
                    more!</p>

            </div>

            <div class="col-md-4 content">
                <img src="@/assets/images/home/my-car.jpg">
                <h2>Flaunt your Ride</h2>
                <p class="subtitle">List your vehicle, add cool images and give us all the details to make people want
                    to ride your car</p>

            </div>

            <div class="col-md-4 content">
                <img src="@/assets/images/home/rental1.jpg">
                <h2>Start Sharing</h2>
                <p class="subtitle">Get bookings from pre verified and trusted clients. You will receive bulk payouts on
                    your earning from the car.</p>

            </div>

        </div>

    </section>

    <section id="app-features">


        <div class="header">
            <h3>App Features</h3>
            <p>All you need to know to easily use the application and bring more friends onboard!</p>
        </div>

        <div class="row content">
            <div class="col-md-4 content-left">

                <div class="feature">
                    <div>
                        <h2>Choose Your Vehicle Pickup</h2>
                        <p>Decide which day you want to travel and your pickup location / destination</p>
                    </div>
                    <font-awesome-icon :icon="['fa','map-marker-alt']"></font-awesome-icon>
                </div>

                <div class="feature">
                    <div>
                        <h2>See Trusted Cars</h2>
                        <p>Choose from a huge range of Vehicle types which are pre-verified by our team</p>
                    </div>
                    <font-awesome-icon :icon="['fa','user-shield']"></font-awesome-icon>
                </div>

                <div class="feature">
                    <div>
                        <h2>Manage Your Bookings</h2>
                        <p>Decide which day you want to travel and your pickup location / destination</p>
                    </div>
                    <font-awesome-icon :icon="['fa','address-card']"></font-awesome-icon>
                </div>

            </div>
            <div class="col-md-4">
                <img src="@/assets/images/home/app1.png" class="app-image"/>
            </div>
            <div class="col-md-4 content-right">

                <div class="feature">
                    <font-awesome-icon :icon="['fa','map-marker-alt']"></font-awesome-icon>
                    <div>
                        <h2>Choose Your Vehicle Pickup</h2>
                        <p>Decide which day you want to travel and your pickup location / destination</p>
                    </div>

                </div>

                <div class="feature">
                    <font-awesome-icon :icon="['fa','map-marked-alt']"></font-awesome-icon>
                    <div>
                        <h2>See Trusted Cars</h2>
                        <p>Choose from a huge range of Vehicle types which are pre-verified by our team</p>
                    </div>

                </div>

                <div class="feature">
                    <font-awesome-icon :icon="['fa','info-circle']"></font-awesome-icon>
                    <div>
                        <h2>Customer Support</h2>
                        <p>Reach out to us for any query or help with your bookings</p>
                    </div>

                </div>

            </div>
        </div>
    </section>

    <section id="experience">
        <div class="container">

            <h5>Browse By Experience</h5>
            <Carousel :items-to-show="SlideCount" class="mt-4">
                <template>
                    <Slide :key="1" class="experience-item">
                        <img src="@/assets/images/home/car-types/luxury.jpg"/>
                        <div class="d-flex">
                            <label>Luxury Cars</label>
                        </div>

                    </Slide>

                    <Slide :key="2" class="experience-item">
                        <img src="@/assets/images/home/car-types/classic.jpg"/>
                        <div class="d-flex">
                            <label>Classic</label>
                        </div>

                    </Slide>

                    <Slide :key="3" class="experience-item">
                        <img src="@/assets/images/home/car-types/convertible.jpg"/>
                        <div class="d-flex">
                            <label>Convertible</label>
                        </div>

                    </Slide>

                    <Slide :key="4" class="experience-item">
                        <img src="@/assets/images/home/car-types/sports.jpg"/>
                        <div class="d-flex">
                            <label>Sports Car</label>
                        </div>

                    </Slide>

                    <Slide :key="4" class="experience-item">
                        <img src="@/assets/images/home/car-types/caravan.jpg"/>
                        <div class="d-flex">
                            <label>Caravan</label>
                        </div>

                    </Slide>

                    <Slide :key="4" class="experience-item">
                        <img src="@/assets/images/home/car-types/suv.jpg"/>
                        <div class="d-flex">
                            <label>SUV</label>
                        </div>
                    </Slide>

                    <Slide :key="4" class="experience-item">
                        <img src="@/assets/images/home/car-types/micro.jpg"/>
                        <div class="d-flex">
                            <label>Micro Vehicle</label>
                        </div>
                    </Slide>
                </template>
                <template #addons>
                    <Navigation :index="10"/>
                </template>
            </Carousel>

            <p class="text-center mt-5">We offer a wide selection of Vehicles to choose from with top of the line care
                covered with Insurance to keep you free from all worries</p>
        </div>
    </section>

    <section id="highlight">
        <img src="@/assets/images/home/header1.png">
        <div class="content container">
            <div>
                <h1>Help and Support is always available</h1>
                <p>Join the app and hop in to a journey of comfort and ease. Choosing from a premium range of vehicles
                    on your vacation to booking the fastest share
                    ride on your way to the office, we got it all. We earn when you earn.
                </p>
            </div>

            <a class="btn btn-theme btn-lg" title="NRide Support Video">
                Watch a Video
                <font-awesome-icon :icon="['fa','long-arrow-alt-right']" class="ms-3"></font-awesome-icon>
            </a>

        </div>

    </section>

    <section id="download-app">
        <div class="background"/>
        <div class="container">
            <div class="row align-items-center">

                <div class="col-md-6 order-md-1">
                    <img src="@/assets/images/home/app2.png" class="img-fluid">
                </div>

                <div class="col-md-6 order-md-0">
                    <h1>Start Riding with us Today!</h1>
                    <p>Choose your vacation drive or daily commute, book the ride and let us know how was it.</p>

                    <div class="mt-3 app-download-buttons">
                        <a href="https://apps.apple.com/app/nride-carpool-rental/id1607959386" target="_blank">
                        <img src="@/assets/images/app/appstore.png" />
                        </a>

                        <a href="https://play.google.com/store/apps/details?id=com.neighbours.nride" target="_blank">
                        <img src="@/assets/images/app/google_play.png" />
                        </a>
                    </div>
                </div>


            </div>
        </div>
    </section>


</template>

<script>
    import 'vue3-carousel/dist/carousel.css';
    import {Carousel, Slide, Navigation} from 'vue3-carousel';

    export default {
        name: "Home",
        components: {
            Carousel,
            Slide,
            Navigation
        },
        computed: {
            SlideCount() {
                let width = screen.width;
                if (width < 768)
                    return 1;
                else if (width >= 768 && width < 1400)
                    return 3;
                else
                    return 4;
            }
        },
        methods: {
            handleClick() {
            this.$refs.targetRef.scrollIntoView({ behavior: 'smooth' });
    }

    }

 }
  
</script>

<style scoped lang="scss">
    #header {
        height: 95vh;
        display: flex;
        align-items: center;
        background: url("../assets/images/home/home-banner.webp");
        background-repeat: no-repeat;
        background-size: cover;

        .content {
            background: white;
            padding: 3rem 3rem;
            margin-left: 4rem;
            box-shadow: 0 0 16px #fffa;
            width: 40%;
            @media (max-width: 768px) {
                width: 100%;
                margin: 2rem;
                text-align: center;
                padding: 2rem 1.5rem;
            }

            h1 {
                font-size: 3rem;

                @media (max-width: 768px) {
                    font-size: 2.5rem;
                }
            }

            p {
                margin-top: 1rem;
                font-size: 1.2rem;
            }

            .logo-image{
                width : 96px;
                height: 96px;

                 @media (max-width: 768px) {
                    width : 48px;
                    height: 48px;
                }
            }
        }

    }

    #services {
        padding-top: 4rem;

        @media (min-width: 1500px) {
            padding-top: 8rem;
        }

        .service {
            align-items: center;
            margin-bottom: 6rem;

            img {
                width: 100%;
                height: auto;
                padding: 2rem;
            }

            .content {
                h1 {
                    font-size: 3rem;
                }

                p.subtitle {
                    font-size: 1.2rem;
                    width: 70%;
                    margin-top: 2rem;

                    @media (max-width: 768px) {
                        width: 100%;

                    }
                }
            }

            @media (max-width: 768px) {
                text-align: center;

            }
        }
    }

    #owner {

        h1 {
            font-size: 3.5rem;
            @media (max-width: 768px) {
                font-size: 2.5rem;
                text-align: center;
            }
        }

        p {
            font-size: 1.3rem;
            width: 70%;
            @media (max-width: 768px) {
                width: 100%;
                text-align: center;
            }
        }

        div.content {
            margin-top: 3rem;
            padding: 1rem;

            img {
                width: 100%;
                height: 13rem;
                object-fit: cover;
                margin-bottom: 1rem;

                @media (min-width: 1400px) {
                    height: 18rem;

                }
            }

            p {
                width: 100%;
                font-size: 1.1rem;
                text-align: left;
            }
        }
    }

    #app-features {
        background: $theme-color-extra-light;
        padding: 6rem;
        margin-top: 4rem;

        @media (max-width: 768px) {
            padding: 2rem;
        }

        .header {
            text-align: center;

            p {
                margin: 1rem auto;
                width: 50%;
                font-size: 1.3rem;

                @media (max-width: 768px) {
                    width: 100%;
                }
            }
        }

        .content {
            margin-top: 4rem;
            align-items: center;

            @media (max-width: 768px) {
                margin-top: 1rem;
            }


            .app-image {
                width: 100%;
                height: 40rem;
                object-fit: contain;
            }

            h2 {
                font-size: 1.5rem;
            }

            p {
                font-size: 1.1rem;
            }

            .content-left {
                text-align: right;
            }

            .content-right {
                text-align: left;
            }

            .feature {
                display: flex;
                align-items: baseline;
                margin: 4rem 0;

                svg {
                    margin: 0 1.5rem;
                    color: $theme-color-deep;
                }
            }
        }

    }

    #experience {
        margin-top: 2rem;
        padding: 6rem 0;

        h5 {
            font-weight: bold;
        }

        .experience-item {
            padding: 0.5rem;
            display: block;

            img {

                width: 100%;
                height: 18rem;
                display: block;
                object-fit: cover;
                border-radius: 1rem;
                background: black;
            }

            div {
                padding: 1rem;
                display: block;

                label {
                    font-size: 0.8rem;
                    color: $theme-color;
                    font-weight: 900;
                    text-transform: uppercase;
                    letter-spacing: 0.1rem;
                    font-family: $heading-font;
                }
            }
        }

        p {
            font-size: 1.5rem;
            width: 50%;
            margin: 0 auto;
            color: black;
            position: relative;
            padding-bottom: 1rem;

            &:after {
                content: "";
                position: absolute;
                width: 8rem;
                border-radius: 4rem;
                height: 4px;
                background: $theme-color;
                left: 50%;
                transform: translate(-50%, 0);
                bottom: 0;
            }

            @media (max-width: 768px) {
                width: 100%;
            }
        }

    }


    #highlight {
        img {
            width: 100%;
            height: 40rem;
            object-fit: cover;

            @media (max-width: 768px) {
                height: 20rem;
            }
        }

        .content {
            margin-top: 3rem;
            display: flex;
            align-items: center;

            @media (max-width: 768px) {
                flex-direction: column;
                text-align: center;
            }

            h1 {
                font-weight: 600;
            }

            p {
                font-size: 1.2rem;
                color: black;
                margin-top: 1.5rem;
            }

            div {
                padding-right: 4rem;
                @media (max-width: 768px) {
                    padding-right: 0;
                }
            }

            a {
                margin: 0 2rem;
                white-space: nowrap;
            }
        }
    }


    #download-app {
        position: relative;
        margin-top: 3rem;
        color: white;

        @media (max-width: 768px) {
            padding-bottom: 2rem;
        }

        h1 {
            color: white;
        }

        p {
            font-size: 1.3rem;
            margin-top: 2rem;
        }

        div.background {
            background: $theme-color-med;
            position: absolute;
            width: 100%;
            height: 80%;
            z-index: -1;
            bottom: 0;
            left: 0;
        }
    }

</style>