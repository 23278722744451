<template>
        <section class="content">
        <div class="container">
            <h1>Cancelling a Trip</h1>

            <p class="mt-3"><i>
                You can cancel your trip yourself within the NeighboursRide mobile app on iOS or Android, directly from your trip page. Select your current trip and you'll see the Cancel button.
            </i>
            </p>

            <h5 class="mt-5">How to Cancel</h5>
            <p>
               You can use NRide mobile apps (iOS or Android) to cancel a trip. You can cancel on our Rental / Share page available on your Accounts tab. Select your current trip and you'll see the Cancel button. 
                Click on the button and follow the prompts to cancel your trip:</p>
               <p> Once a trip has started, it cannot be canceled and is non-refundable.
            </p>
            <h6 class="mt-4">Cancellation Fees</h6>
            <p class=" w-75">In certain cases, NeighboursRide may charge a cancellation fee to compensate the car host for the time the car was booked and unavailable to other potential guests. That said, we understand that life happens, and you may need to cancel a trip from time to time. You can avoid paying a cancellation fee by:
            <ul> 
                <li> Canceling within 1 hour of booking your trip</li>
                <li> Canceling with at least 24 hours notice</li></ul></p>

            <h6 class="mt-4">1-Hour Cancellation Grace Period</h6>
            <p class="w-75">When you book a NeighboursRide rental or shareride, you have a 1 hour grace period to cancel the trip with no fees assessed, as long as you cancel before the trip start time.
             For example, at 4:15PM you book a trip that starts at 6:00PM. You have 1 hour from the time of booking (up to 5:15PM) to cancel with no fees.
             Once a trip starts, it cannot be canceled.</p>
            
            <h6 class="mt-4">24 Hours Notice</h6>
            <p class=" w-75">Beyond the 1-hour cancellation grace period, you can still cancel a trip at no charge as long as it's more than 24 hours before the trip start time.
                 If you cancel outside of your 1-hour cancellation grace period, the following cancellation fees apply:</p>
             <ul> <li>Cancellation more than 24 hours before the Trip starts: no fee.</li>
                 <li>Cancellation less than 24 hours before the Trip starts: 50% of Trip rate.</li>
            </ul>
             <p> When you cancel a trip, a cancellation fee may be charged at NeighboursRide 's sole discretion. This fee is charged to compensate for the time the car was booked and unavailable for other potential guests.</p>
            
            <h6 class="mt-4">Extreme Weather Events and Emergencies</h6>
            <p class=" w-75">The safety of our community members is always our top priority, and we understand that severe weather events can make it unsafe and inadvisable to be out on the roads.
            If you're experiencing an extreme weather event (as deemed by the National Weather Service or another government agency) and you'd like to cancel your trip, please contact us via the help button on the trip page in your app - we are here to help.</p>
        </div>
    </section>
</template>

<script>
    export default {
        name: "CancellationPolicy"
    }
</script>

<style scoped lang="scss">
.content {
        margin: 6rem 0;
    }
</style>