<template>
  <div class="top">
    <section id="sRide" class="container">
      <div class="accordion" id="accordionExample">
        <div>
         <div class="hsix"> <h6 class="ms-4">General FAQ</h6> </div>
          <div class="p-2">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="false"
                  aria-controls="collapseOne"
                >
                  What is sRide
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <strong
                    >sRide is a trusted social carpooling app that helps people
                    to carpool by connecting people like you going your way. It
                    uses a real-time matching process to help match people going
                    in the same direction. Some of these users will be car
                    owners (can give ride to other people to share cost) and
                    some of these user will need ride.</strong
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="p-2">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  What is carpooling?
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <strong>
                    Carpooling is when two or more people share a ride together.
                    It allows individuals to reduce the costs and alleviate
                    associated problems of congestion and emissions.
                  </strong>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="p-2">
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingThree">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                When can I use sRide to carpool?
              </button>
            </h2>
            <div
              id="collapseThree"
              class="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <strong>
                  <ul>
                    <li>Commuting to work</li>
                    <li>Travel to hometown on weekend</li>
                    <li>
                      Drop or pick kids to school or after school activities.
                    </li>
                    <li>
                      Get to movies, shopping, events and conferences with
                      friends
                    </li>
                  </ul>
                </strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="sRideFAQ" class="container">
      <div class="accordion" id="accordionExample">
        <div>
           <div class="hsix"> <h6 class="ms-4">sRide App FAQ</h6></div>
          <div class="p-2">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingf">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsef"
                  aria-expanded="false"
                  aria-controls="collapseOne"
                >
                  Do I need a car to carpool with sRide?
                </button>
              </h2>
              <div
                id="collapsef"
                class="accordion-collapse collapse"
                aria-labelledby="headingf"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <strong
                    >No. Many people are happy to offer rides in exchange for
                    sharing the costs of the ride.
                  </strong>
                </div>
              </div>
            </div>
          </div>
          <div class="p-2">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingfive">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefive"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  How safe is carpooling?
                </button>
              </h2>
              <div
                id="collapsefive"
                class="accordion-collapse collapse"
                aria-labelledby="headingfive"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <strong>
                    The safety of our members is our top priority, and we have
                    made our application very secure to ensure that aspect of
                    carpooling. All our users are verified for their identity
                    through phone and work email. User review is mandatory at
                    the end of each ride so each user gets more and more review
                    comments as they complete their rides. The review ratings
                    received by past co-riders help other people to select these
                    users based on the average review ratings they have got.
                  </strong>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="p-2">
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingsix">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapsesix"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                When can I use sRide to carpool?
              </button>
            </h2>
            <div
              id="collapsesix"
              class="accordion-collapse collapse"
              aria-labelledby="headingsix"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <strong>
                  <ul>
                    <li>Commuting to work</li>
                    <li>Travel to hometown on weekend</li>
                    <li>
                      Drop or pick kids to school or after school activities.
                    </li>
                    <li>
                      Get to movies, shopping, events and conferences with
                      friends
                    </li>
                  </ul>
                </strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="sRide" class="container">
      <div class="accordion" id="accordionExample">
        <div>
           <div class="hsix"> <h6 class="ms-4">How to use the sRide Carpool app</h6></div>
          <div class="p-2">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingseven">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseseven"
                  aria-expanded="false"
                  aria-controls="collapseseven"
                >How do I post a ride?
                </button>
              </h2>
              <div
                id="collapseseven"
                class="accordion-collapse collapse"
                aria-labelledby="headingseven"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <strong
                    >To post a single ride, you may click on the + button and add a single trip. To add a recurring ride, you can add your Home & Office location along with your morning and evening preferred trip time. The app will automatically post trip for your daily (Monday-Friday) if you set a recurring trip. You may edit your single trip or recurring trip anytime. For more details, check “How to edit the trips?”
                  </strong>
                </div>
              </div>
            </div>
          </div>
          <div class="p-2">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingeight">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseeight"
                  aria-expanded="false"
                  aria-controls="collapseeight"
                > How do I edit a ride?
                </button>
              </h2>
              <div
                id="collapseeight"
                class="accordion-collapse collapse"
                aria-labelledby="headingeight"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <strong>
                   To edit a trip, you may go to My Trips tabs and click on the edit icon.
                  </strong>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="p-2">
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingnine">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapsenine"
                aria-expanded="false"
                aria-controls="collapsenine"
              >How do I check my matches?
              </button>
            </h2>
            <div
              id="collapsenine"
              class="accordion-collapse collapse"
              aria-labelledby="headingnine"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <strong>
                 Once you post a trip, you’ll instantly get your matches on the app. If there are no current matches, we will save your trip preferences and send you a match notification as soon as there is a match
You may also check matches under “Rides” Section –> “Matches”.
You may also search for matches using the “Search” option on the top of the app
                </strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "faqs",
  components: {},
  computed: {},
  methods: {},
};
</script>

<style scoped lang="scss">
.top{
  padding: 0.5rem;
  @media (min-width: 1000px) {
  padding: 1.5rem;
  }
}
.hsix {
  border-left: blue solid 4px !important;
  margin-bottom: 0.4rem;
   @media (min-width: 1000px) {
  border-left: blue solid 4px !important;
  padding: 0.5rem !important;
  margin-bottom: 0.5rem;
  }
}
#sRide {
  padding: 0.5rem;
  @media (min-width: 1500px) {
    padding: 1.9rem;
    width: 100%;
  }
  .content {
    h1 {
      font-size: 3rem;
    }
  }
  .accordion-button {
    border: none !important;
    padding: 1.6rem 1.25rem !important;
  }
}

#sRideFAQ {
  padding: 0.5rem;
  @media (min-width: 1500px) {
    padding: 1.9rem;
    width: 100%;
  }
  .content {
    h1 {
      font-size: 3rem;
    }
  }
  .accordion-button {
    border: none !important;
    padding: 1.6rem 1.25rem !important;
  }
}
</style>