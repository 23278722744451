import {createApp} from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import "./css/theme.scss";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// eslint-disable-next-line no-unused-vars
import {library} from "../src/css/icons";

const TawkMessengerVue = require('@tawk.to/tawk-messenger-vue-3');
createApp(App)
    .component('font-awesome-icon', FontAwesomeIcon)
    .use(store)
    .use(router)
    .use(TawkMessengerVue, {
        propertyId : '64cb5d1e94cf5d49dc6824fb',
        widgetId : '1h6t67ggr'
        })
    .mount("#app");


