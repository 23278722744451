<template>
  <section id="header">
    <div class="content">
      <img class = "logo-image" src="@/assets/logo_transparent.png" />
      <h1>Become a nRide Host</h1>
      <p>If you own a car, we can help you setup a business where you can earn money daily</p>
      <div class="mt-3 app-download-buttons">
        <a href="https://apps.apple.com/app/nride-carpool-rental/id1607959386" target="_blank">
          <img src="@/assets/images/app/appstore.png" />
        </a>

        <a href="https://play.google.com/store/apps/details?id=com.neighbours.nride" target="_blank">
          <img src="@/assets/images/app/google_play.png" />
        </a>
      </div>

      <span class="more" @click="handleClick()">Learn more about this application</span>
    </div>
  </section>

  <section id="con" class="container">
    <div class="header" ref="targetRef">
      <h1>Be Your Own Boss</h1>
      <p class="subtitle">
        Start your own business with no barrier by listing a car you already
        own. No sign-up charges, no monthly fees. Becoming a car sharing
        entrepreneur on NRide platform made easy. NRide, the
        leading neighbourhood digital Rental/Carsharing App system. It provides
        communities with a cost-effective alternative to car ownership. Whether
        you're looking to start a business, or you currently manage your own
        vehicle business, NRide offers technology and resources to help
        you succeed
      </p>
    </div>
   </section>

  <section id="process" class="container">
    
    <h1>How to Start</h1>
      <p class="subtitle">
        Install NRide App from Google Play Store or Apple App Store, and open an account to
        enroll your car(s) and get connected. Submit your info and insurance or
        purchase one from our providers. Our on-boarding team will help set it up
        for approval and you can manage everything from your phone.
      </p>
    
    <div class="row proc">
      <div class="col-md-3">
        <img src="@/assets/images/host/info.jpg" />
      </div>
      <div class="col-md-9 content">
        <h3>List your car for free</h3>
        <p class="subtitle">
          Upload photos and information of your car. Listing takes
          about 10 minutes and is free — no sign-up charges, no monthly fees.
          Once our team approves it, you are ready to go.
        </p>
      </div>
    </div>

    <div class="row proc">
      <div class="col-md-3">
        <img src="@/assets/images/host/rules.jpg" />
      </div>
      <div class="col-md-9 content">
        <h3>Set your Price & Rules</h3>
        <p class="subtitle">
          For car rentals, set your own daily price and lay your ground rules.
          For share rides, the app automatically adjust your price to maximize your earnings.
        </p>
      </div>
    </div>

    <div class="row proc">
      <div class="col-md-3">
        <img src="@/assets/images/host/guest.jpg" />
      </div>
      <div class="col-md-9 content">
        <h3>Welcome your guests</h3>
        <p class="subtitle">
          When a guest books your car, you can directly call/message from the app.
          Check your guest in with the app, and earn when the trip is over.
        </p>
      </div>
    </div>

    <div class="row proc">
      <div class="col-md-3">
        <img src="@/assets/images/host/money.jpg" />
      </div>
      <div class="col-md-9 content">
        <h3>Sit Back & Earn</h3>
        <p class="subtitle">
          Get paid via direct bank deposit every week. You’ll earn
          75% – 85% of the trip price, depending on the vehicle you
          own.
        </p>
      </div>
    </div>
  </section>


<section id="responsibility">
    <h1>Host Responsibilities</h1>
    <p class="subtitle">
      Hosts like you make NeighboursRide possible, and you have unique
      responsibilities. These NeighboursRide community standards will contribute
      to an excellent experience for you and your guests.
    </p>

    <div class="row p-3">
      <div class="col-md-6">
        <div class="content">
        <img src="@/assets/images/home/register.jpg" />
        <h2>Accuracy</h2>
        <p class="subtitle">
          Keep your car’s profile and your account information up to date and
          accurate so guests can locate your vehicle quickly and communicate
          with you through the NRide app as needed. List your vehicle's home
          location and special features correctly.
        </p>
        </div>
      </div>
      <div class="col-md-6">
        <div class="content">
        <img src="@/assets/images/host/clean.jpg" />
        <h2>Cleanliness</h2>
        <p class="subtitle">
          Keep your cars clean, well-maintained, and smoke-free. Remember that guests
          can leave reviews about their experience, and positive reviews often
          lead to more bookings. Remove all personal items from your car before renting.
        </p>
        </div>
      </div>
    </div>
    <div class="row p-3">
      <div class="col-md-6">
        <div class="content">
        <img src="@/assets/images/host/accountibility.jpg" />
        <h2>Accountability</h2>
        <p class="subtitle">
          Ensure your car is at the home location, so the guest has no trouble
          finding it when booked. Be proactive in disabling the rental when your car is not available.
          If you need to cancel a booked trip, notify the guest immediately and state genuine reasons.
        </p>
        </div>
      </div>

      <div class="col-md-6">
        <div class="content">
        <img src="@/assets/images/host/communication.jpg" />
        <h2>Communication</h2>
        <p class="subtitle">
          Be prompt, respectful, and clear in conversations with your guests.
          Questions might come up during a trip, and you are the person who
          knows your car best. Instructions should be simple and easy to follow for guests.
        </p>
        </div>
      </div>
    </div>
  </section>

  <section id="video" class="container">
    <div class="row vid">
      <div class="col-md-6 content">
          <h1>Still Confused?</h1>
          <p class="subtitle">Check out our step by step tutorial video on how to become a host and manage your bookings.</p>
      </div>
      
      <div class="col-md-6">
        <div
          class="embed-responsive embed-responsive-16by9"
          poster="@/assets/images/share/smartphone.png"
        >
          <iframe
            height="400"
            class="w-100 vid-height"
            src="https://www.youtube.com/embed/8kd6zfUfuzk?rel=0"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </section>



  <section id="fees">
    <div class="background" />
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6">
            <img class="image-size" src="@/assets/images/home/my-car.jpg" />
        </div>

        <div class="col-md-6 text-pos">
          <h1>Fee and Commission Schedule</h1>
          <p class="subtitle">
            We're building a safe and trusted car sharing community. The fees
            outlined below are meant to encourage the growth and success of a
            happy community and discourage any irresponsible behavior. When
            small issues do arise, it's always best if Members can work them out
            between themselves. However, if you see anything that looks
            particularly wrong,please contact us Standard Fees
          </p>

          <p class="subtitle">
            Standard Fees compensate NeighboursRide for the services provided in
            operating the platform and facilitating trips, including maintaining
            insurance coverage for trips, providing 24/7 roadside assistance,
            and running our site, hardware, and applications. These fees are in
            addition to the hourly, daily, and/or weekly rate of the car.
            NeighboursRide currently charges a 10% commission on all trips
            booked, which commission is applied to the initial reservation price
            and any trip extensions, and which Guests pay directly to
            NeighboursRide. Each trip extension is processed as a new trip and
            is billed separately from the initial booking.
          </p>
        </div>
      </div>
    </div>
  </section>

  

  <section id="testimonials">
    <div class="container">
      <h1>Host Spotlight</h1>
      <Carousel :items-to-show="SlideCount" class="mt-4">
        <template>
          <Slide :key="1" class="testimonials-item">
            <div class="card p-4">
              <img
                class="ms-auto me-auto"
                src="@/assets/images/host/testimonial-photos/testi2.jpg"
              />
              <label class="text-center mb-4 fw-bold">Justin Olika</label>
              <div class="d-flex">
                <label
                  >“NeighboursRide has proven to be more than just a car sharing
                  platform. I've seen how it's contactless capabilities are
                  actually essential in providing safety and peace of mind to
                  both myself and my renters, especially in this recent pandemic
                  society. I love that the tech behind NeighboursRide automates
                  so much for me in my car sharing business so that I don’t have
                  as much leg work to do manually." —Jordan</label
                >
              </div>
            </div>
          </Slide>
          <Slide :key="2" class="testimonials-item">
            <div class="card p-4">
              <img
                class="ms-auto me-auto"
                src="@/assets/images/host/testimonial-photos/testi1.jpg"
              />
              <label class="text-center mb-4 fw-bold">Adeniran David</label>
              <div class="d-flex">
                <label
                  >“NeighboursRide has proven to be more than just a car sharing
                  platform. I've seen how it's contactless capabilities are
                  actually essential in providing safety and peace of mind to
                  both myself and my renters, especially in this recent pandemic
                  society. I love that the tech behind NeighboursRide automates
                  so much for me in my car sharing business so that I don’t have
                  as much leg work to do manually." —Jordan</label
                >
              </div>
            </div>
          </Slide>
          <Slide :key="3" class="testimonials-item">
            <div class="card p-4">
              <img
                class="ms-auto me-auto"
                src="@/assets/images/host/testimonial-photos/testi3.jpg"
              />
              <label class="text-center mb-4 fw-bold">Adebayo Lukman</label>
              <div class="d-flex">
                <label
                  >“NeighboursRide has proven to be more than just a car sharing
                  platform. I've seen how it's contactless capabilities are
                  actually essential in providing safety and peace of mind to
                  both myself and my renters, especially in this recent pandemic
                  society. I love that the tech behind NeighboursRide automates
                  so much for me in my car sharing business so that I don’t have
                  as much leg work to do manually." —Jordan</label
                >
              </div>
            </div>
          </Slide>
          <Slide :key="4" class="testimonials-item">
            <div class="card p-4">
              <img
                class="ms-auto me-auto"
                src="@/assets/images/host/testimonial-photos/testi4.jpg"
              />
              <label class="text-center mb-4 fw-bold">Mike Agbebaku</label>
              <div class="d-flex">
                <label
                  >“NeighboursRide has proven to be more than just a car sharing
                  platform. I've seen how it's contactless capabilities are
                  actually essential in providing safety and peace of mind to
                  both myself and my renters, especially in this recent pandemic
                  society. I love that the tech behind NeighboursRide automates
                  so much for me in my car sharing business so that I don’t have
                  as much leg work to do manually." —Jordan</label
                >
              </div>
            </div>
          </Slide>
        </template>
        <template #addons>
          <Navigation :index="10" />
        </template>
      </Carousel>
    </div>
  </section>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";
export default {
  name: "HostService",
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  computed: {
    SlideCount() {
      let width = screen.width;
      console.log(width);
      if (width < 768)
                    return 1;
                else if (width >= 768 && width < 1400)
                    return 2;
                else
                    return 3;
    },
  },
   methods: {
            handleClick() {
            this.$refs.targetRef.scrollIntoView({ behavior: 'smooth' });
    }

    }
};
</script>

<style scoped lang="scss">
#header {
  height: 95vh;
  display: flex;
  align-items: center;
  background: url("../../assets/images/host/host-banner.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: center;

  .content {
    background: white;
    padding: 3rem 3rem;
   // margin-left: 52%;
    margin-left: 4rem;
    box-shadow: 0 0 16px #fffa;
    width: 40%;
    @media (max-width: 768px) {
      width: 100%;
      margin: 2rem;
      text-align: center;
      padding: 2rem 1.5rem;
    }

    h1 {
      font-size: 3rem;

      @media (max-width: 768px) {
        font-size: 2.5rem;
      }
    }

    p {
      margin-top: 1rem;
      font-size: 1.2rem;
    }
  .logo-image{
                width : 96px;
                height: 96px;

                 @media (max-width: 768px) {
                    width : 48px;
                    height: 48px;
                }
            }
    .width {
      width: 100%;
    }
  }
}

#con{
  padding: 1rem;
  margin-top: 2rem;

 @media (max-width: 768px) {
    padding: 1rem;
  }
        .header {
          h1{
            font-size: 3.5rem;
              @media (max-width: 768px) {
              font-size: 2.5rem;
              }
          }
           p.subtitle {
            font-size: 1.2rem;
             width: 100%;
            margin-top: 2rem;
          }
        }
}

#process {
  padding-top: 4rem;
  margin-bottom: 6rem;
  

  h1{
    font-size: 3.5rem;
    @media (max-width: 768px) {
          font-size: 2.5rem;
        }
  }
    
  p.subtitle {
        font-size: 1.2rem;
        width: 100%;
        margin-top: 2rem;
      }


  .proc {
    align-items: center;
    //margin-bottom: 6rem;

    img {
      width: 100%;
      height: auto;
      padding: 2rem;
    }
  
    .content {
      h1 {
        font-size: 3rem;
      }
      
    }

    @media (max-width: 768px) {
      text-align: center;
    }
  }
}

#responsibility {
     margin-top: 4rem;
     padding: 6rem;
     background: #01503215;

  @media (max-width: 768px) {
      padding: 1rem;
  }

  h1 {
    font-size: 3.5rem;
    @media (max-width: 768px) {
      font-size: 2.5rem;
      text-align: center;
    }
  }

 p.subtitle {
        font-size: 1.2rem;
        width: 100%;
        margin-top: 2rem;

        @media (max-width: 768px) {
          width: 100%;
        }
      }

  div.content {
    margin: 1rem;
    padding: 1rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background: white;
    border-radius: 1rem;

    @media (max-width: 768px) {
          margin: 1rem 0rem;
        }

    img {
      width: 100%;
      height: 15rem;
      object-fit: cover;
      margin-bottom: 1rem;

      @media (min-width: 1400px) {
        // height: 18rem;
      }
    }

    p {
      width: 100%;
      font-size: 1.1rem;
      text-align: left;
    }
  }

  @media (min-width: 768px) {
    .col-md-3 {
      flex: 0 0 auto;
      width: 22%;
    }
  }
}

#testimonials {
  margin-top: 4rem;
//   padding: 6rem 0;

  h5 {
    font-weight: bold;
  }
  h1{
    font-size: 3.5rem;
    @media (max-width: 768px) {
          font-size: 2.5rem;
        }
  }

  .testimonials-item {
    padding: 1rem;
    display: block;
    img {
      width: 90px;
      height: 90px;
      display: block;
      object-fit: cover;
      border-radius: 50rem;
      background: black;
    }

    div {
      display: block;

      label {
        font-size: 1.1rem;
      }
    }
  }

  p {
    font-size: 1.5rem;
    width: 50%;
    margin: 0 auto;
    color: black;
    position: relative;
    padding-bottom: 1rem;

    &:after {
      content: "";
      position: absolute;
      width: 8rem;
      border-radius: 4rem;
      height: 4px;
      background: $theme-color;
      left: 50%;
      transform: translate(-50%, 0);
      bottom: 0;
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }
}

#fees {
  position: relative;
  margin-top: 3rem;
  color: white;

  @media (max-width: 768px) {
    padding-bottom: 2rem;
  }

  h1 {
    color: white;
  }

  p.subtitle {
        font-size: 1.2rem;
        margin-top: 2rem;
        width: 100%;
      }

  div.background {
    background: $theme-color-med;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    bottom: 0;
    left: 0;
  }
  h1 {
    color: white;
  }

  .image-size{
    width: 90%;
    margin: 1rem 0;
    @media (max-width: 768px) {
    width: 100%;
    }
  }

  .text-pos{
    margin: 3rem 0;
    @media (max-width: 768px) {
    margin: 2rem 0;
    }
  }
}

#video {
  padding-top: 2rem;

  @media (min-width: 1500px) {
    padding-top: 8rem;
  }

  .vid {
    align-items: center;
    margin-bottom: 6rem;

    img {
      width: 100%;
      height: auto;
      padding: 2rem;
    }

    .content {
      h1{
        font-size: 3.5rem;
        @media (max-width: 768px) {
              font-size: 2.5rem;
            }
      }

      p.subtitle {
        font-size: 1.2rem;
        width: 70%;
        margin-top: 2rem;

        @media (max-width: 768px) {
          width: 100%;
        }
      }
      .vid-height {
        height: 200px;
        @media (max-width: 768px) {
          height: 40px;
        }
      }
      iframe {
        height: 24rem !important;
      }
    }

    @media (max-width: 768px) {
      text-align: center;
    }
  }
}
</style>