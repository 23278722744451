import { library } from '@fortawesome/fontawesome-svg-core'

import {faFacebookF, faLinkedinIn,faTwitter, faYoutube, faInstagram,} from "@fortawesome/free-brands-svg-icons";

import {
    faCheckCircle,
    faTimesCircle,
    faCircle,
    faCar,
    faFile,
    faPhoneAlt,
    faEnvelopeOpen,
    faTh,
    faUserSecret,
    faMapMarkerAlt,
    faAddressCard,
    faUserShield,
    faMapMarkedAlt,
    faInfoCircle,
    faLongArrowAltRight,
} from "@fortawesome/free-solid-svg-icons";



library.add(
    faCheckCircle,
    faTimesCircle,
    faCircle,
    faCar,
    faFile,
    faPhoneAlt,
    faEnvelopeOpen,
    faTh,
    faUserSecret,
    faMapMarkerAlt,
    faAddressCard,
    faUserShield,
    faMapMarkedAlt,
    faInfoCircle,
    faLongArrowAltRight,
    faFacebookF,
    faLinkedinIn,
    faTwitter,
    faYoutube,
    faInstagram
);

export default library;
