import {createRouter, createWebHistory} from "vue-router";
import Home from "../views/Home.vue";
import Policies from "../views/Policies";
import Aboutus from "../views/aboutus";
 import Faqs from "../views/faqs";
import PrivacyPolicy from "../views/policies/PrivacyPolicy";
import TermsPolicy from "../views/policies/TermsPolicy";
import PaymentPolicy from "../views/policies/PaymentPolicy";
import InsurancePolicy from "../views/policies/InsurancePolicy";
import SafetyPolicy from "../views/policies/SafetyPolicy";
import CancellationPolicy from "../views/policies/CancellationPolicy";
import RentalService from "../views/services/RentalService";
import CorporateService from "../views/services/CorporateService";
import HostService from "../views/services/HostService";
import ShareService from "../views/services/ShareService";
import Checklists from "../views/Checklists";
import rentalchecklist from "../views/checklists/rentalchecklist";
import StripeHostAccount from "../views/transactions/stripe/StripeHostAccount";
import HandleTransaction from "../views/transactions/HandleTransaction";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
        meta: {
            title: 'nRide - Neighbours Ride',
            metaTags: [
                {
                    name: 'description',
                    content: 'Best Rental and Ride sharing platform'
                }
            ]
        }
    },
    {
        path: "/services/rentals",
        name: "RentalService",
        component: RentalService,
        meta: {
            title: 'nRide - Rentals',
            metaTags: [
                {
                    name: 'description',
                    content: 'Best Rental and Ride sharing platform'
                }
            ]
        }
    },
    {
        path: "/services/shares",
        name: "ShareService",
        component: ShareService,
        meta: {
            title: 'nRide - Shares',
            metaTags: [
                {
                    name: 'description',
                    content: 'Best Rental and Ride sharing platform'
                }
            ]
        }
    },
    {
        path: "/services/host",
        name: "HostService",
        component: HostService,
        meta: {
            title: 'nRide - Hosts',
            metaTags: [
                {
                    name: 'description',
                    content: 'Best Rental and Ride sharing platform'
                }
            ]
        }
    },
    {
        path: "/services/corporate",
        name: "CorporateService",
        component: CorporateService,
        meta: {
            title: 'nRide - Corporate Bookings',
            metaTags: [
                {
                    name: 'description',
                    content: 'Best Rental and Ride sharing platform'
                }
            ]
        }
    },
    {
        path: "/aboutus",
        name: "Aboutus",
        component: Aboutus,
    },
    {
        path: "/faqs",
        name: "Faqs",
        component: Faqs,
    },
    {
        path: "/policies",
        name: "Policies",
        component: Policies,
        children: [
            {
                path: "terms-and-conditions",
                name: "TermsConditions",
                component: TermsPolicy
            },
            {
                path: "privacy-policy",
                name: "PrivacyPolicy",
                component: PrivacyPolicy
            },
            {
                path: "payment-policy",
                name: "PaymentPolicy",
                component: PaymentPolicy
            },
            {
                path: "insurance-policy",
                name: "InsurancePolicy",
                component: InsurancePolicy
            },
            {
                path: "safety-policy",
                name: "SafetyPolicy",
                component: SafetyPolicy
            },
            {
                path: "cancellation-policy",
                name: "CancellationPolicy",
                component: CancellationPolicy
            }
        ]
    },
    {
        path: "/stripe/completion/verify/:slug",
        name: "Stripe Host Account",
        component: StripeHostAccount,
    },
    {
        path: "/handle/payment",
        name: "HandlePayments",
        component: HandleTransaction,
        props: (route) => (
            {
                gateway: route.query.gateway,
                id: route.query.id,
                type: route.query.type,
            }
        ),
    },
    {
    path: "/checklists",
    name: "Checklists",
    component: Checklists,
    children: [
        {
            path: "rentalchecklist",
            name: "rentalchecklist",
            component: rentalchecklist
        }
    ]
}
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
